/* accordion */
$(function () {
    $('.Accordion').each(function (i, accordion) {
        var accordionTrigger = '.AccordionTrigger';
        var accordionContent = '.AccordionContent';
        var accordionParent = $(this);
        var findAccordionTrigger = accordionParent.find(accordionTrigger);
        var findAccordionContent = accordionParent.find(accordionContent);

        // Apply ARIA and display values on load
        if (findAccordionTrigger.hasClass('__active')) {
            accordionParent.addClass('__active');
            findAccordionTrigger.attr('aria-expanded', true);
            findAccordionContent.show();
            findAccordionContent.attr('aria-hidden', false);
        } else {
            accordionParent.removeClass('__active');
            findAccordionTrigger.attr('aria-expanded', false);
            findAccordionContent.hide();
            findAccordionContent.attr('aria-hidden', true);
        }

        // On click of accordion trigger
        findAccordionTrigger.click(function (e) {
            e.preventDefault();
            /* close an open accordion */
            if ($(this).hasClass('__active')) {
                $(this).attr('aria-expanded', false);
                $(accordionTrigger).removeClass('__active');
                accordionParent.removeClass('__active');
                findAccordionContent.slideUp();
                findAccordionContent.attr('aria-hidden', true);
            } else {
                $(this).attr('aria-expanded', true);
                $(this).parents('.Accordion').siblings().children('.AccordionTrigger').attr('aria-expanded', false);
                $('.Accordion').removeClass('__active');
                $(this).addClass('__active');
                accordionParent.addClass('__active');
                $(this).parents('.Accordion').siblings().children('.AccordionTrigger').removeClass('__active');
                findAccordionContent.slideDown();
                $(this).parents('.Accordion').siblings().children('.AccordionContent').slideUp();
                findAccordionContent.attr('aria-hidden', false);
                $(this).parents('.Accordion').siblings().children('.AccordionContent').attr('aria-hidden', true);
            }
        })
    })
});