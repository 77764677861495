import CountUp from 'countup.js/dist/countUp.js';
require('waypoints/lib/jquery.waypoints.js')

document.querySelectorAll(".js-count-up").forEach(element => {
  const {
    start,
    end,
    decimals,
    duration
  } = element.dataset;
  const countUp = new CountUp(
    element,
    start,
    end,
    decimals,
    duration,
    element.dataset);


  new Waypoint({
    element,
    handler: direction => {
      if (direction === "down") {
        countUp.start();
      }
    },
    offset: "95%"
  });

});