// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// load fonts
WebFont.load({
    google: {
        families: ['Inter:400,500,700&display=swap']
    },
    active: function () {
        sessionStorage.fontsLoaded = true
    }
});

//Load Libraries
// require('flatpickr/dist/flatpickr.min');
// require('magnific-popup/dist/jquery.magnific-popup.min');
require('select2/dist/js/select2.full.min');
require('@accessible360/accessible-slick/slick/slick.min');

//Load Modules
// require('./modules/bannerHomeHeight');
require('./modules/accordion');
// require('./modules/animatein');
require('./modules/bannerVideo');
require('./modules/formInputs');
// require('./modules/videoButtonReplacer');
// require('./modules/videoPopup');
require('./modules/menu');
// require('./modules/modal');
// require('./modules/scrollTo');
require('./modules/searchToggle');
// require('./modules/shareSocial');
// require('./modules/sideMenu');
require('./modules/tabs');
require('./modules/alert');
require('./modules/sliders');
require('./modules/stats');
require('./modules/toggleBannerPersons');
require('./modules/safetriangle');
require('./modules/stickyButton');
