$(function () {
    let isRtl = false;
    if (document.getElementById('Main').dir == "rtl"){
        isRtl = true;
    }
    $(".newsslider-slick").each(function () {
        var thisElement = $(this);
        var parentSlider = thisElement.closest('.newsslider-wrap');
        thisElement.slick({
            fade: false,
            dots: false,
            infinite: false,
            prevArrow: parentSlider.find('.sliderPrevButton'),
            nextArrow: parentSlider.find('.sliderNextButton'),
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            rtl: isRtl,
            responsive: [{
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 4
                    }
                },
            ]
        });
    })

    $(".imageslider-slick").each(function () {
        var thisElement = $(this);
        var parentSlider = thisElement.closest('.imageslider-wrap');
        thisElement.slick({
            fade: false,
            dots: true,
            infinite: true,
            prevArrow: parentSlider.find('.sliderPrevButton'),
            nextArrow: parentSlider.find('.sliderNextButton'),
            slidesToShow: 1,
            slidesToScroll: 1,
            rtl: isRtl
        });
    })
});