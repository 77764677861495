$(function () {
    var body = $('body');
    var nav = $('.nav');
    var burger = $('.burger');
    var nav_trigger = $('.nav').find('ul li.menu-item-has-children');
    var megamenu_trigger = $('.nav').find('ul li.menu-item-has-megamenu>a');
    var megamenu_child_trigger = $('.nav').find('ul li.menu-item-has-megamenu .TabTrigger');
    var nav_align = 'right';
    var break_point = 1200;
    var browser_width = window.innerWidth;
    var nav_width = 300;
    var nav_window = $('<div class="navwindow"></div>');
    body.append(nav_window);
    var nav_wrap = $('.navwrap');

    nav_window.click(function (event) {
        nav_reset();
    });

    //burger click
    burger.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('__active');
        if (body.hasClass('__mobile')) {
            nav_reset();
        } else {
            nav_launch();
        }
    });

    /*nav reset*/
    var nav_reset = function nav_reset() {
        burger.removeClass('__active');
        nav.removeClass('__subopen');
        nav_window.hide();
        body.removeClass('__mobile');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul, div:not(.TabContent)').removeClass('__active');
    };

    /*nav launch*/
    var nav_launch = function nav_launch() {
        nav_window.show();
        body.addClass('__mobile');
        nav_wrap.addClass('__active');
    };



    var nav_ul = $('.nav ul li > ul, .nav ul li > .menu-item-is-megamenu');
    var tab_content = $(' .nav ul li > .menu-item-is-megamenu .megamenu-list');

    function initMenu() {
        var browser_width = window.innerWidth;

        $(".navwrap ul").on("mouseenter focusin", function () {
            $('.navwrap ul').each(function () {
                var elem = $(this)
                var rect = elem[0].getBoundingClientRect();
                if (rect.x + rect.width > window.innerWidth) {
                    $(this).addClass('__left');
                }
            });
        });

        // nav dropdown on focus/keyboard navigation
        if (browser_width >= break_point) {
            $('.menu-item').focusin(function () {
                $(this).addClass('__active');
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').focusout(function () {
                $(this).removeClass('__active');
                $(this).children('button').attr('aria-expanded', false);
            });
            $('.menu-item').mouseover(function () {
                $(this).children('button').attr('aria-expanded', true);
            });
            $('.menu-item').mouseout(function () {
                $(this).children('button').attr('aria-expanded', false);
            });
        }

        if (browser_width < break_point) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).children('ul').length > 0) {
                    $(this)
                        .children('ul')
                        .addClass('__active');
                    $(this).closest('nav').scrollTop(0);
                    $(this).closest('nav').addClass('__subopen');
                }
            });

            megamenu_trigger.click(function (event) {
                event.stopPropagation();

                $(this)
                    .parent()
                    .toggleClass('__active');
                $(this)
                    .parent()
                    .children('.menu-item-is-megamenu')
                    .toggleClass('__active');
                $(this).closest('nav').scrollTop(0);
                $(this).closest('nav').addClass('__subopen');

            });

            megamenu_child_trigger.click(function (event) {
                $(this)
                    .toggleClass('__active');
                $(this)
                    .parent().siblings('.menu-item-is-megamenu')
                    .toggleClass('__active');
            });

            // Disable root item link click
            $('.menu-item-has-children > a, .menu-item-has-megamenu > a, a.megamenu-link-has-children').click(function (event) {
                event.preventDefault();
            })
        }

        if (browser_width < break_point) {

            var back_menu = $('.backto');
            back_menu.click(function (event) {
                event.preventDefault();
                event.stopPropagation();
                $(this)
                    .closest('.menu-item-has-children').parent('ul').parent('nav').removeClass('__subopen');
                $(this)
                    .closest('ul').parent('nav').removeClass('__subopen');
                $(this)
                    .parent('ul')
                    .removeClass('__active');
                $(this)
                    .closest('.menu-item-is-megamenu')
                    .removeClass('__active');
                $(this)
                    .parents('li')
                    .parent('ul')
                    .addClass('__active');
            });

        } else {
            nav_reset();
        }

        // Touch device check
        const isTouchDevice = () => {
            return window.matchMedia("(pointer: coarse)").matches
        }
        // If desktop and touch device, disable link click and add extra view link
        if (browser_width > break_point && isTouchDevice()) {
            nav_trigger.click(function (event) {
                event.stopPropagation();
                if ($(this).children('ul').length > 0) {
                    event.preventDefault();
                }
            });
        }
    }

    initMenu();

    $(window).on('resize', function () {
        initMenu();
    });

});