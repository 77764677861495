$(function () {
    function getVideoElement() {
        return document.querySelector(
            window.innerWidth < 768
                ? '.banner-video-el' // Mobile video
                : 'video.d-none.d-sm-block' // Desktop video
        );
    }

    function pause_play() {
        let video = getVideoElement();
        let playPauseButton = document.querySelector('.playpause');
        let playPauseButtonText = document.querySelector(
            '#banner-video-controls-button-text'
        );

        if (playPauseButton) {
            playPauseButton.classList.add('__pause');

            // Play/Pause button
            playPauseButton.onclick = function () {
                if (video.paused) {
                    // is paused, click to play
                    video.play();
                    playPauseButton.classList.remove('__play');
                    playPauseButton.classList.add('__pause');
                    playPauseButtonText.textContent = 'Pause';
                } else {
                    // is playing, click to pause
                    video.pause();
                    playPauseButton.classList.add('__play');
                    playPauseButton.classList.remove('__pause');
                    playPauseButtonText.textContent = 'Play';
                }
            };
        }
    }

    pause_play();

    $(window).on('resize', function () {
        pause_play();
    });
});