/* Sticky navigation for pages with child navigation */
$(document).ready(function () {
    if ($('.jumplinks-wrap').length) {
        var pageNavigation = $(".jumplinks-wrap");
        var originalOffsetTop = pageNavigation.offset().top + 80;

        $(window).on("scroll", function () {
            var currentScrollTop = $(this).scrollTop();

            if (currentScrollTop >= originalOffsetTop) {
                pageNavigation.addClass("__sticky");
            } else {
                pageNavigation.removeClass("__sticky");
            }
        });
    }
});