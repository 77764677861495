if (window.innerWidth < 575) {
    $(document).on("scroll", function () {
        $(".global-button.sticky").css("bottom", "30px");
        if ($('body').hasClass('show_global_buttons')) {
            $(".jumplinks .button.sticky").css("bottom", "30px");
        }
    });
}

if (window.innerWidth < 575) {
    if ($('.jumplinks .button.sticky').length) {
        $(".global-button.sticky").addClass('hideFirst');
    }
}