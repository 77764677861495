const break_point = 1200;
const max_width = 1600;
const tolerance = 2;

window.addEventListener('DOMContentLoaded', function () {
    if (window.innerWidth >= break_point) {
        safetriangleTargets = document.querySelectorAll('.safetriangleTarget');

        safetriangleTargets.forEach((safetriangleTarget) => {
            safetriangleTarget.addEventListener('mousemove', (event) => {
                safetriangle = safetriangleTarget.querySelector('.safetriangle');

                // Set Y coord
                let safetriangleTargetHeight = safetriangleTarget.offsetHeight;
                let safetriangleOffsetY = safetriangleTarget.getBoundingClientRect().top;
                let safeTriangleCoordY = event.clientY - safetriangleOffsetY - tolerance;

                // Set X coord
                let safeTriangleCoordX = event.clientX - (window.innerWidth >= max_width ? (window.innerWidth - max_width) / 2 : 0);

                // Set CSS variables
                safetriangle.style.setProperty('--safe-triangle-x', safeTriangleCoordX + 'px');
                safetriangle.style.setProperty('--safe-triangle-y', safeTriangleCoordY + 'px');
                safetriangle.style.setProperty('--safe-triangle-theight', safetriangleTargetHeight + 'px');
            });
        })
    };
});