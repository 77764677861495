$( ".filters input[type='checkbox']" ).on( "change", function() {
    if($(this).hasClass('all')) {
        if($(this).prop( "checked" )){
            $(this).closest('.__checkbox').find("input[type='checkbox']").prop( "checked", true );
        } else {
            $(this).closest('.__checkbox').find("input[type='checkbox']").prop( "checked", false );
        }
    } else {
        
        if($(this).prop( "checked" ) == false){
            $(this).closest('.__checkbox').find("input.all").prop( "checked", false );
        }
    }
} );